<template>
  <div v-show="currentVideo" class="player">
    <video controls ref="player"></video>
    <a href="#" class="close" @click.prevent="close"></a>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import shaka from "shaka-player";

export default {
  name: "Player",
  computed: {
    ...mapGetters(["currentVideo"]),
  },
  data() {
    return {
      player: null,
    };
  },
  watch: {
    currentVideo(newVideo) {
      if (newVideo) {
        this.player = new shaka.Player(this.$refs.player);
        if(newVideo.licenseToken){
            this.player.configure({
              drm :{
                servers: {
                  'com.widevine.alpha': "https://4d7e415e.drm-widevine-licensing.axprod.net/AcquireLicense"
                }
              }
            });
            this.player.getNetworkingEngine().registerRequestFilter(function (type, request) {
                if (type === shaka.net.NetworkingEngine.RequestType.LICENSE) {
                    request.headers['X-AxDRM-Message'] = newVideo.licenseToken;
                }
            });
        }
        this.player
          .load(newVideo.url)
          .then(() => {
            console.log("The video has now been loaded!");
            this.$refs.player.play();
          })
          .catch(console.error);
      }
    },
  },
  methods: {
    close() {
      this.player.detach();
      this.setCurrentVideo(null);
    },
    ...mapActions(["setCurrentVideo"]),
  },
};
</script>

<style scoped>
.player {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background: rgba(0, 0, 0, 90%);
  padding: 2rem;
}

video {
  width: 90%;
  height: 100%;
  aspect-ratio: 16 / 9;
  background: #000;
}

.close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  height: 2rem;
  width: 2rem;
  background: url("../assets/icons/x-circle.svg");
  background-size: cover;
}
</style>
