<template>
  <v-row>
    <v-col>
      <Gallery
        v-if="videos.free.length > 0"
        :title="$t('message.free_videos')"
        :videos="videos.free"
      />
      <Gallery
        v-if="videos.auth.length > 0"
        :title="$t('message.auth_videos')"
        :videos="videos.auth"
      />
      <Gallery
        v-if="videos.nft.length > 0"
        :title="$t('message.nft_videos')"
        :videos="videos.nft"
      />
      <Player />
    </v-col>
  </v-row>
</template>

<script>
import Gallery from "@/components/Gallery.vue";
import Player from "@/components/Player.vue";

import { mapGetters } from "vuex";

export default {
  name: "Home",
  data() {
    return {};
  },
  components: {
    Gallery,
    Player,
  },
  computed: {
    ...mapGetters(["videos"]),
  },
};
</script>

<style>
</style>
