<template>
  <div class="gallery">
    <div class="gallery-title">{{ title }}</div>
    <section>
      <ul class="px-8">
        <li
          v-for="(video, index) in videos"
          :key="index"
          :class="{ playable: video.url }"
        >
          <img v-if="video.uhd" class="icon" src="@/assets/icons/4k.png" />
          <img class="preview" :src="video.img" alt="" />
          <span>{{ video.title }}</span>
          <div class="badge">{{ video.level }}</div>
          <v-overlay class="lock" opacity="0.75">
            <span>
              <v-icon color="var(--color-text)" x-large>mdi-lock</v-icon>
            </span>
          </v-overlay>
          <div class="play" @click="play(video, $event)">
            <span>
              <v-icon color="var(--color-text)" x-large>mdi-play</v-icon>
            </span>
          </div>
        </li>
      </ul>
    </section>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Gallery",
  props: {
    videos: Array,
    title: String,
  },
  methods: {
    play(video, e) {
      e.preventDefault();
      if (video.url) {
        this.setCurrentVideo(video);
      }
    },
    ...mapActions(["setCurrentVideo"]),
  },
};
</script>

<style scoped>
.gallery {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.gallery-title {
  font-size: 2em;
  text-transform: capitalize;
  text-align: center;
  padding: 0.5em 0;
}

section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  flex-grow: 1;
}

ul {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(25vw, 1fr));
  grid-gap: 1rem;
}

@media (max-width: 1000px) {
  ul {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 700px) {
  ul {
    grid-template-columns: 1fr;
  }
}

li {
  position: relative;
  list-style: none;
  overflow: hidden;
}

/* Title style */
li > span {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 2rem 0.5rem 0.5rem 0.5rem;
  font-size: 2rem;
  z-index: 1;
  pointer-events: none;
  background-image: linear-gradient(
    180deg,
    transparent,
    rgba(0, 0, 0, 50%) 50%
  );
}

/* Badge style */
li .badge {
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
  background: var(--color-accent);
  transform: translateX(2rem) translateY(-0.7rem) rotate(45deg) scale(1.4);
  padding: 2rem 2rem 0.5rem 2rem;
  z-index: 6;
  pointer-events: none;
  width: 6rem;
  text-transform: uppercase;
  font-size: 0.7em;
  font-weight: bold;
  transition: all 0.3s ease;
}

.preview {
  width: 100%;
  height: 100%;
  object-fit: cover;
  aspect-ratio: 16 / 9;
}

.icon {
  width: 4.5rem;
  height: 4rem;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0.5rem;
}

/* Common style */
.play,
.lock {
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  transition: all 0.3s ease;
}

.lock span,
.play span {
  display: block;
  border-radius: 50%;
  padding: 1rem;
}

/* Lock style */
.lock {
  cursor: not-allowed;
}

.lock span {
  background-color: var(--color-chip);
  border: 2px solid var(--color-text);
}

/* Play style */
.play {
  cursor: pointer;
}

.play span {
  background-color: var(--color-accent);
}

/* Transition style */
li.playable .lock,
li.playable .badge {
  opacity: 0;
  z-index: -1;
  transform: translateY(-100%);
}

li:not(.playable) .play {
  opacity: 0;
  z-index: -1;
  transform: translateY(100%);
}
</style>